.icons-and-text {
  padding: 50px 0px;
  h2 {
    margin-bottom: 0px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    margin-top: 50px;
    @media (max-width: 767px) {
      display: block;
    }

    .icon-item {
      @media (max-width: 767px) {
        margin-top: 35px;
      }
    }
    h2 {
      margin-top: 25px;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      margin-top: 25px;
      @media (max-width: 767px) {
        margin-top: 13px;
      }
    }

    .image-container {
      margin: 0 auto;
      img {
        display: block;
        max-width: 100%;
        width: 275px;
        height: 275px;
        margin: 0 auto;
      }
    }
  }
}
