.case-study-footer {
  padding: 50px 0px;
  .download-btn {
    margin: 20px auto 0px auto;
    padding: 0px 10px;
    height: 59px;
    max-width: 400px;
    border-radius: 18px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.1s ease-in-out;
    background-color: red;
    &:hover {
      scale: 1.07;
    }
  }
  p {
    text-align: left;
    font-size: 16px;
    margin-top: 25px;
  }

  .text-right {
    text-align: right;
    display: block;
    margin-bottom: 15px;
  }
}
