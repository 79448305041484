* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font: normal normal normal 16px/21px Graphik Regular;
  color: #272727;
  font-weight: 400;
  // margin-top: 94px;
  // @media (max-width: 576px) {
  //   margin-top: 60px;
  // }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1170px;
  }
}

.container {
  @media (max-width: 576px) {
    padding: 0px 20px;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 670px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 870px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

.container {
  @media (max-width: 991px) {
    padding: 0px 30px;
  }
  @media (max-width: 576px) {
    padding: 0px 20px;
  }
}

h1 {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0px;
  @media (max-width: 576px) {
    font-size: 30px;
    line-height: 36px;
  }
}

h2 {
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0px;
  @media (max-width: 576px) {
    font-size: 21px;
    line-height: 30px;
  }
}

h3 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0px;
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 28px;
  }
}

p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 26px;
  }
}

ul {
  li {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    margin-bottom: 15px;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

a {
  color: inherit;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
}

.brand-font {
  font-family: var(--brandfont), "Graphik Regular", sans-serif;
}

.hcp-product {
  font-family: "TitilliumWeb", sans-serif;
}

.public-product {
  font-family: "Graphik Regular", sans-serif;
}

.hcp-product-nav {
  font-family: "TitilliumWeb", sans-serif;
  font-weight: 600;
}
.public-product-nav {
  .dropdown-content {
    a {
      font-family: "Graphik Semibold", sans-serif;
    }
  }
}

.h1 {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0px;
  @media (max-width: 576px) {
    font-size: 30px;
    line-height: 36px;
  }
}

.block-anchor {
  display: block;
  transform: translateY(calc(var(--nav-offset) * -1));
}

p {
  a {
    text-decoration: none;
    font-weight: 700;
  }
}

sup a {
  font-weight: normal;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #2caabe;
  }
}

h1,
h2,
h3,
h4,
h5 {
  a {
    text-decoration: none;
  }
}

#externalLinkModal {
  .modal-content {
    padding: 10px 20px;
    border: none;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ae1834),
      to(#752938)
    );
    background: -moz-linear-gradient(left, #ae1834 0, #752938 100%);
    background: linear-gradient(90deg, #ae1834 0, #752938 100%);
    color: #fff;
    fill: #fff;
    border-radius: 20px;
    width: 100%;
    margin: 0;
  }
  #externalLinkModalClose {
    fill: #fff;
    cursor: pointer;
  }

  .modal-footer {
    border: none;
  }
  .modal-header {
    border: none;
    justify-content: flex-end;
  }

  h2 {
    font-size: 30px;
    font-family: Graphik Semibold, sans-serif;
    text-align: center;
    line-height: 38px;
    @media (max-width: 576px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .button {
    height: 59px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ae1834;
    text-decoration: none;
    font-size: 20px;
    font-family: "TitilliumWeb", sans-serif;
    gap: 30px;
    background-color: #ffffff;
    font-weight: 700;
    white-space: nowrap;
    padding: 0px 30px;
    transition: all 0.1s ease-in-out;
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      right: 20px;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      height: unset;
      padding: 10px 20px;
      white-space: unset;
    }
    &:hover {
      scale: 1.03;
    }
  }
}

#hcpModal {
  .modal-dialog {
    max-width: 1200px;
  }
  .modal-content {
    padding: 60px 20px;
    border: none;
    width: 100%;
    max-width: 1300px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ae1834),
      to(#752938)
    );
    background: -moz-linear-gradient(left, #ae1834 0, #752938 100%);
    background: linear-gradient(90deg, #ae1834 0, #752938 100%);
    color: #fff;
    fill: #fff;
    border-radius: 20px;
    width: 100%;
    margin: 0;
    @media (max-height: 767px) {
      padding: 30px 20px;
    }
  }
  #hcpModalClose {
    fill: #fff;
    cursor: pointer;
  }

  .modal-body {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin-top: 25px;
  }

  .modal-footer {
    border: none;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .modal-header {
    border: none;
    justify-content: flex-end;
  }

  h2 {
    font-size: 45px;
    line-height: 1.2;
    font-family: Graphik Semibold, sans-serif;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 30px;
      line-height: 1.2;
    }
  }

  .button {
    height: 59px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    max-width: 500px;
    justify-content: space-around;
    color: #ae1834;
    text-decoration: none;
    font-size: 20px;
    font-family: "TitilliumWeb", sans-serif;
    gap: 30px;
    background-color: #ffffff;
    font-weight: 700;
    white-space: nowrap;
    padding: 0px 30px;
    transition: all 0.1s ease-in-out;
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      right: 20px;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      height: unset;
      padding: 10px 40px;
      white-space: unset;
    }
    &:hover {
      scale: 1.03;
    }
  }
}

body span.gform-loader {
  display: none !important;
}

.gform_confirmation_message {
  text-align: center !important;
  font-size: 20px !important;
  margin-top: 20px !important;
}

.gfield_required {
  display: none !important;
}

.of-hidden {
  overflow: hidden;
}

.janda-font {
  h1.brand-font {
    letter-spacing: 0.5px;
  }
  h2.brand-font {
    letter-spacing: 0.7px;
  }
  h3.brand-font {
    letter-spacing: 0.8px;
  }
}

.invisible {
  opacity: 0;
}
