.prescribing-information {
  padding: 55px 0px;
  .hcp-image {
    max-width: 278px;
    margin-bottom: 50px;
    width: 100%;
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  h1,
  h2 {
    margin-bottom: 25px;

    p {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0px;
      @media (max-width: 576px) {
        font-size: 21px;
        line-height: 30px;
      }
    }
  }

  .large-panel {
    border-radius: 20px;
    margin-top: 30px;
    padding: 50px 100px;
    @media (max-width: 1200px) {
      padding: 40px 70px;
    }
    @media (max-width: 576px) {
      padding: 30px 30px;
    }
    h2.h1 {
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 25px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      position: relative;
      gap: 200px;
      @media (max-width: 1200px) {
        gap: 130px;
      }
      @media (max-width: 767px) {
        display: block;
      }
      h2 {
        margin-bottom: 45px;
        @media (max-width: 767px) {
          margin-bottom: 25px;
        }
      }
      .item {
        min-width: 377px;
        @media (max-width: 991px) {
          min-width: 278px;
        }
        @media (max-width: 576px) {
          min-width: unset;
        }
      }
      &:after {
        content: "";
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        background: #fff;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  .cta-block {
    padding: 50px 0px 30px 0px;
  }

  .cta-item {
    padding-bottom: 50px;
    border-bottom: 1px solid #272727;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    align-items: flex-end;
    gap: 25px;
    @media (max-width: 767px) {
      display: block;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0px;
    }
    &:first-of-type {
      padding-top: 0px;
    }
    .content {
      max-width: 475px;

      p {
        margin-top: 20px;
        margin-bottom: 0px;
      }
    }
    .link {
      width: 230px;
      height: 59px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      transition: all 0.1s ease-in-out;
      @media (max-width: 767px) {
        margin-top: 25px;
      }
      &:hover {
        scale: 1.07;
      }
    }
  }
}
