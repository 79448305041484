.star-rating {
  padding: 50px 0px;

  input[type="submit"] {
    width: 171px !important;
    height: 59px !important;
    border-radius: 18px !important;
    font: normal normal bold 20px/28px TitilliumWeb !important;
    padding-bottom: 1px !important;
    transition: all 0.1s ease-in-out !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:after {
      content: "<img src='/wp-content/themes/dlp/src/images/arrow-white.svg' />";
    }
    &:hover {
      transform: scale(1.05);
    }
  }

  .gfield_radio {
    justify-content: center !important;
    flex-direction: row !important;
  }

  label {
    display: block;
    width: 63px;
    height: 63px;
    mask-repeat: no-repeat !important;
    mask-position: center;
    mask-size: contain;
    -webkit-mask-image: url("/wp-content/themes/dlp/src/images/star.svg");
    mask-image: url("/wp-content/themes/dlp/src/images/star.svg");
    cursor: pointer;
    border: none;
    margin-left: 0px !important;
    font-size: 0px !important;

    @media (max-width: 576px) {
      width: 45px;
      height: 45px;
    }
  }

  input[type="radio"] {
    display: none !important;
    margin: 0 !important;
  }

  .gchoice:has(input[type="radio"]:checked) ~ .gchoice label {
    background-color: #e4e4e4;
  }

  p {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
  }
}
