.case-studies {
  padding: 50px 0px;
  .download-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    gap: 19px;

    @media (max-width: 576px) {
      display: block;
    }

    .download-item {
      max-width: 278px;
      text-decoration: none;
      flex: 1;
      @media (max-width: 576px) {
        max-width: unset;
      }
      @media (max-width: 576px) {
        margin-top: 35px;
        display: block;
      }
    }
    h3 {
      margin-top: 15px;
      margin-bottom: 0px;

      @media (max-width: 767px) {
        font-size: 21px;
        line-height: 30px;
      }
    }

    .download-btn {
      margin: 20px auto 0px auto;
      width: 209px;
      height: 59px;
      border-radius: 18px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      transition: all 0.1s ease-in-out;

      &:hover {
        scale: 1.07;
      }
    }

    .image-container {
      position: relative;
      margin: 0 auto;
      width: 278px;
      aspect-ratio: 1;
      @media (max-width: 576px) {
        width: auto;
      }
      img {
        object-fit: contain;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
