.form-block {
  padding: 50px 0px;

  .form-content-inner {
    h1,
    h2 {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: 576px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    p {
      margin-bottom: 35px;
      text-align: center;
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }
  }

  .gfield_label {
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
    @media (max-width: 576px) {
      font-size: 16px !important;
      margin-bottom: 5px !important;
    }
  }

  .gform_fields {
    column-gap: 20px !important;
    row-gap: 20px !important;
  }

  input[type="text"],
  input[type="email"] {
    height: 59px !important;
    border: none !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 10px #00000029 !important;
    border-radius: 18px !important;
    font-size: 18px !important;
    letter-spacing: 0px !important;
    color: #272727 !important;
    opacity: 1 !important;
    padding: 0px 15px !important;
    @media (max-width: 576px) {
      font-size: 16px !important;
    }
  }

  .fake-datepicker {
    input[type="text"] {
      display: none !important;
    }
  }

  .ginput_container_radio {
    background-color: #fff;
    padding: 20px 32px;
    box-shadow: 0px 0px 10px #00000029 !important;
    border-radius: 18px !important;

    .gform-field-label {
      font-size: 18px;
      @media (max-width: 576px) {
        font-size: 16px !important;
      }
    }
    .gchoice {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .gfield-choice-input {
      box-shadow: none !important;
    }
    .gfield-choice-input:checked {
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }

  input[type="submit"] {
    width: 223px !important;
    height: 59px !important;
    border-radius: 18px !important;
    font: normal normal bold 20px/28px TitilliumWeb !important;
    padding-bottom: 1px !important;
    transition: all 0.1s ease-in-out !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:after {
      content: "<img src='/wp-content/themes/dlp/src/images/arrow-white.svg' />";
    }
    &:hover {
      transform: scale(1.05);
    }
  }

  .ui-datepicker-inline {
    border: none !important;
    padding: 20px 35px;
    background: #ffffff !important;
    box-shadow: 0px 0px 10px #00000029 !important;
    border-radius: 18px !important;
    @media (max-width: 576px) {
      padding: 15px 20px;
    }
  }
  .ui-datepicker-title {
    font-size: 18px;
    margin: 0px 0px 0px 14px;
    text-align: left;
    @media (max-width: 576px) {
      font-size: 16px !important;
    }
  }
  .ui-datepicker-prev,
  .ui-datepicker-next {
    cursor: pointer;
    border: none;
    top: 2px;
  }
  .ui-datepicker-next {
    right: 7px;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-image: url("/wp-content/themes/dlp/src/images/arrow-right.svg");
    mask-image: url("/wp-content/themes/dlp/src/images/arrow-right.svg");
  }
  .ui-datepicker-prev {
    right: 50px;
    left: unset;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-image: url("/wp-content/themes/dlp/src/images/arrow-right.svg");
    transform: rotate(180deg);
    mask-image: url("/wp-content/themes/dlp/src/images/arrow-right.svg");
  }
  .ui-datepicker-calendar {
    border-spacing: 0px;

    th {
      span {
        font-size: 18px;
        @media (max-width: 576px) {
          font-size: 16px !important;
        }
      }
    }

    tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr:first-child td {
      border-top-style: solid;
    }
    tr td:first-child {
      border-left-style: solid;
    }

    td {
      padding: 0px;
      border-style: none solid solid none;
      overflow: hidden;
      //   padding-top: 9px;
      span,
      a {
        background: transparent;
        height: 34px;
        font-size: 18px;
        color: #272727;
        text-align: center;
        border: none !important;
        padding-top: 7px;
        @media (max-width: 576px) {
          font-size: 16px !important;
        }
      }
    }
    .ui-state-active {
      color: #fff !important;
      font-weight: 700;
    }
  }
}
