.plain-text {
  min-height: 590px;
  padding: 80px 0px;
  @media (max-width: 576px) {
    padding: 40px 0px;
  }
  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 50px;
    text-align: left;
    color: rgb(174, 24, 52);
    margin-bottom: 3rem;
    font-family: "TitilliumWeb";
    @media (max-width: 767px) {
      font-size: 40px;
      margin-bottom: 1rem;
      line-height: 50px;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
    color: rgb(174, 24, 52);
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}
