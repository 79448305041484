.tabs-block {
  padding: 50px 0px 50px 0px;
  .tabs {
    position: relative;
    width: 100%;
  }

  h2.h1 {
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  .tabs {
    .tab-header {
      margin: 0 auto;
      padding: 10px 20px 0px 20px;
      position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      z-index: 2;
      @media (max-width: 576px) {
        padding: 10px 10px 0px 10px;
      }
      > a {
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        font-size: 18px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        border-radius: 18px 18px 0px 0px;
        @media (max-width: 576px) {
          border-radius: 12px 12px 0px 0px;
          padding: 7px 0px;
        }
      }
      > a.active {
        background-color: #fff;

        box-shadow: 0px 0px 10px #00000029;
      }
    }

    .tab-icon {
      height: 125px;
      width: 125px;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;

      border-radius: 20px;
      box-shadow: 0px 3px 6px #00000029;
      @media (max-width: 767px) {
        width: 48px;
        height: 48px;
        border-radius: 8px;
      }

      img {
        color: #fff;
        padding: 0px 25px;
        width: 100%;
        @media (max-width: 767px) {
          padding: 0px;
          max-width: 63%;
          display: block;
        }
      }
    }

    .tab-body {
      margin: 0 auto;
      width: 100%;
      overflow: hidden;
      border-radius: 20px;
      position: relative;
      box-shadow: 0px 0px 10px #00000029;
      @media (max-width: 576px) {
        border-radius: 12px;
      }
      > div {
        // position: absolute;
        width: 100%;
        padding: 20px 100px;

        min-height: 275px;
        opacity: 0;
        display: none;
        top: -100vh;
        background-color: #fff;
        @media (max-width: 576px) {
          min-height: 175px;
          padding: 20px 50px;
        }
        .tab-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;

          img {
            max-width: 140px;
            @media (max-width: 767px) {
              display: none;
            }
          }

          .content {
            padding-left: 57px;
            @media (max-width: 767px) {
              padding-left: 0px;
            }
            @media (max-width: 576px) {
              text-align: center;
            }
            p {
              margin-top: 25px;
              @media (max-width: 576px) {
                margin-top: 10px;
              }
            }
          }
        }
        > * {
          margin: 10px 0px;
        }
        > p {
          font-size: 15px;
        }
      }
      > div.active {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        top: 0px;
        opacity: 1;
        transition: top 0ms ease-in-out 0ms, opacity 500ms ease-in-out 0ms;
      }
    }
  }
}
