.graph-block {
  padding: 50px 0px;

  .text-image-block-inner {
    h1,
    h2 {
      margin-bottom: 20px;
      text-align: center;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;

      br {
        display: block;
        margin: 20px 0;
        content: " ";
      }
      @media (max-width: 576px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .underline {
      text-decoration: underline;
    }

    ul {
      text-align: center;
      display: block;
      padding-left: 0px;
      li {
        list-style: none;
        // position: relative;
        // width: fit-content;
        // display: block;
        // margin: 0 auto;
        font-size: 18px;
        line-height: 1.4;
        margin-top: 25px;
        display: block;
        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 26px;
          margin-top: 15px;
        }

        &:before {
          content: "";
          background: #272727;
          height: 5px;
          width: 5px;
          display: inline-block;
          margin-bottom: 3px;
          margin-right: 10px;
        }
      }
    }

    p {
      margin-bottom: 35px;
      text-align: center;
    }

    .panel {
      padding: 30px;
      border-radius: 40px;
      max-width: 575px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 767px) {
        box-shadow: 0px 3px 6px #00000029;
      }
      h3 {
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }
  .key-container {
    max-width: fit-content;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    @media (max-width: 576px) {
      display: block;
    }
    .key-item {
      display: inline-flex;
      align-items: center;
      @media (max-width: 576px) {
        display: flex;
      }
      gap: 10px;
      margin-top: 25px;
      p {
        margin: 0;
        font-weight: 600;
      }
      .dot {
        height: 28px;
        width: 28px;
      }
    }
  }

  .image.horizontal {
    .image-container {
      padding-top: 50%;
    }
  }
  .image {
    margin-left: auto;
    margin-right: auto;
    // max-width: 475px;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 20px;
    }

    @media (max-width: 576px) {
      img {
        &:first-of-type {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    .mobile-image {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
