.text-image-block.remove-gap-top {
  padding-top: 0px !important;
  margin-top: -100px;
  @media (max-width: 767px) {
    margin-top: -20px;
  }
}
.text-image-block {
  padding: 50px 0px;

  .text-image-block-inner.flipped {
    flex-direction: row-reverse;

    .image {
      .image-container {
        margin-left: 0px;
        margin-right: 115px;
        @media (max-width: 1200px) {
          margin-right: 75px;
          margin-left: 0px;
        }
        @media (max-width: 767px) {
          margin-right: 0px;
        }
      }
    }
  }

  .text-image-block-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
    }
    > div {
      flex: 1;
    }
    h1,
    h2 {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 20px;
      @media (max-width: 576px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    p {
      margin-bottom: 35px;
    }

    .panel {
      padding: 29px;
      border-radius: 40px;
      margin-top: 25px;

      &:first-of-type {
        margin-top: 0px;
      }
      @media (max-width: 767px) {
        box-shadow: 0px 3px 6px #00000029;
      }
      h3 {
        margin-bottom: 0px;
      }
    }

    .uic {
      margin-left: 115px;
      @media (max-width: 1200px) {
        margin-left: 75px;
      }
      @media (max-width: 767px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .image {
      .image-container {
        @media (max-width: 1200px) {
          margin-left: 75px;
        }
        @media (max-width: 767px) {
          margin-left: 0px;
          margin-right: 0px;
          margin-top: 40px;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
