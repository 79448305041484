.references {
  padding: 55px 0px;
  .hcp-image {
    max-width: 278px;
    margin-bottom: 50px;
    width: 100%;
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  h1,
  h2 {
    margin-bottom: 25px;
  }

  .number {
    font-weight: 700;
  }
  p a {
    font-weight: normal;
    &:hover {
      text-decoration: underline;
    }
  }

  .reference {
    margin-top: 25px;
    display: block;
    overflow-wrap: break-word;

    .reference-anchor {
      display: block;
      transform: translateY(calc(var(--nav-offset) * -1 - 30px));
    }
  }
}
