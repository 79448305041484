.swoosh {
  box-sizing: content-box;
  position: relative;
  --swoosh-top: #fff;
  --swoosh-bottom: #fff;

  &:before {
    content: "";
    top: -1px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--swoosh-top);
  }
  &:after {
    content: "";
    bottom: -1px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--swoosh-bottom);
  }
  svg {
    min-width: 600px;
    width: 100%;
  }
}

.swoosh.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
