.pie-chart-block {
  padding: 50px 0px;
  h2 {
    margin-bottom: 0px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    margin-top: 50px;

    @media (max-width: 767px) {
      display: block;
    }
    .icon-item {
      @media (max-width: 767px) {
        margin-top: 45px;
      }
    }
    h3 {
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 25px;
    }

    p {
      text-align: center;
      margin-top: 7px;
    }

    .key-container {
      max-width: fit-content;
      margin: 0 auto;
      .key-item {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 25px;
        p {
          margin: 0;
          font-weight: 600;
        }
        .dot {
          height: 28px;
          width: 28px;
          border-radius: 50%;
        }
      }
    }

    .image-container {
      padding-top: 80%;
      position: relative;
      max-width: 350px;
      margin: 0 auto;
      img {
        object-fit: contain;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
