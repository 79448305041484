.public-page {
  padding: 70px 0px;
  @media (max-width: 576px) {
    padding: 40px 0px;
  }
  .public-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    @media (max-width: 1400px) {
      grid-row-gap: 70px;
      grid-column-gap: 70px;
    }
    @media (max-width: 1200px) {
      display: block;
    }
  }

  .item {
    h2 {
      font-size: 45px;
      font-family: Graphik Semibold, sans-serif;
      line-height: 1.33;
      @media (max-width: 767px) {
        font-size: 34px;
      }
    }
    @media (max-width: 1200px) {
      margin-top: 50px;
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  .link-full {
    height: 59px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    background-color: #752938;
    font-family: "TitilliumWeb", sans-serif;
    margin-top: 40px;
    gap: 30px;
    font-weight: 700;
    white-space: nowrap;
    padding: 0px 45px;
    transition: all 0.1s ease-in-out;
    position: relative;
    svg {
      position: absolute;
      right: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;

      margin-top: 20px;
      white-space: unset;
    }
    &:hover {
      scale: 1.03;
    }
  }
}
