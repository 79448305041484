.homepage {
  &:before {
    content: "";
    position: absolute;
    inset: 0px;
    background-image: url("/wp-content/themes/dlp/src/images/landing.png");
    background-repeat: no-repeat;
    background-position: 20% 50%;
    background-size: cover;
    z-index: -1;
    left: 30vw;
    @media (max-width: 1400px) {
      background-position: 60% 50%;
    }

    @media (max-width: 767px) {
      background-position: center;
      left: 0;
    }
  }
  padding: 80px 0px;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    left: 30vw;
    background: linear-gradient(90deg, white 0%, transparent 60%);
    @media (max-width: 767px) {
      background: rgba($color: #ffffff, $alpha: 0.7);
      left: 0;
    }
  }
  .content {
    max-width: 650px;
    position: relative;
    z-index: 1;
    .homepage-logo {
      max-width: 274px;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        width: 175px;
        margin-bottom: 25px;
      }
    }

    h2 {
      font-size: 34px;
      font-weight: 700;
      font-family: Graphik Semibold, sans-serif;
      line-height: 49px;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 35px;
      }
    }

    p {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 35px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    h1 {
      font-family: Graphik Semibold, sans-serif;
      font-size: 60px;
      line-height: 76px;
      color: #ae1834;
      margin-bottom: 35px;

      @media (max-width: 767px) {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.3;
      }
    }

    .hcp-link {
      height: 59px;
      border-radius: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-family: "TitilliumWeb", sans-serif;
      margin-top: 30px;
      gap: 30px;
      background-color: #ae1834;
      font-weight: 700;
      white-space: nowrap;
      padding: 0px 30px;
      transition: all 0.1s ease-in-out;

      @media (max-width: 767px) {
        font-size: 18px;
        height: unset;
        padding: 10px 20px;
        margin-top: 20px;
        white-space: unset;
      }
      &:hover {
        scale: 1.03;
      }
    }
    .public-link {
      height: 59px;
      border-radius: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 30px;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-family: "TitilliumWeb", sans-serif;
      gap: 30px;
      background-color: #752938;
      font-weight: 700;
      white-space: nowrap;
      padding: 0px 30px;
      transition: all 0.1s ease-in-out;

      @media (max-width: 767px) {
        font-size: 18px;
        height: unset;
        padding: 10px 20px;
        white-space: unset;
        margin-top: 20px;
      }
      &:hover {
        scale: 1.03;
      }
    }
    .patient-link {
      height: 59px;
      margin-top: 30px;
      border-radius: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-family: "TitilliumWeb", sans-serif;
      gap: 30px;
      background-color: #ec621a;
      font-weight: 700;
      white-space: nowrap;
      padding: 0px 30px;
      transition: all 0.1s ease-in-out;

      @media (max-width: 767px) {
        font-size: 18px;
        height: unset;
        padding: 10px 20px;
        margin-top: 20px;
        white-space: unset;
      }
      &:hover {
        scale: 1.03;
      }
    }
  }
}
