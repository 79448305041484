.hcp-page {
  padding: 70px 0px;
  @media (max-width: 576px) {
    padding: 40px 0px;
  }
  .hcp-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    @media (max-width: 1400px) {
      grid-row-gap: 70px;
      grid-column-gap: 70px;
    }
    @media (max-width: 1200px) {
      display: block;
    }
    .logo {
      max-width: 100%;
      max-height: 110px;
      display: block;
      margin: 0 auto;
    }

    .character {
      max-width: 100%;
      height: 320px;
      display: block;
      margin: 30px auto 0px auto;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: rotate(360deg);
      }
    }
  }

  .item {
    @media (max-width: 1200px) {
      margin-top: 50px;
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  .link-full {
    height: auto;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: "TitilliumWeb", sans-serif;
    margin-top: 40px;
    gap: 30px;
    padding: 16px 45px;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    transition: all 0.1s ease-in-out;
    position: relative;
    svg {
      position: absolute;
      right: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;

      margin-top: 20px;
      white-space: unset;
    }
    &:hover {
      scale: 1.03;
    }
  }

  .link-outline {
    height: auto;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ae1834;
    text-decoration: none;
    font-size: 20px;
    line-height: 22px;
    font-family: "TitilliumWeb", sans-serif;
    margin-top: 30px;
    gap: 30px;
    border: 2px solid #ae1834;
    text-align: center;
    background-color: #fff;
    font-weight: 700;
    white-space: normal;
    padding: 16px 45px;
    transition: all 0.1s ease-in-out;
    position: relative;
    svg {
      position: absolute;
      right: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;

      margin-top: 20px;
      white-space: unset;
    }
    &:hover {
      scale: 1.03;
    }
  }

  .intro {
    text-align: center;
    p {
      font-family: "TitilliumWeb", sans-serif;
      font-size: 21px;
    }
    margin-bottom: 70px;

    @media (max-width: 576px) {
      margin-bottom: 40px;
    }
  }
}
