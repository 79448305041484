@font-face {
  font-family: Graphik Regular;
  src: url("/wp-content/themes/dlp/src/fonts/Graphik/Graphik-Regular.otf")
    format("opentype");
}
@font-face {
  font-family: Graphik Semibold;
  src: url("/wp-content/themes/dlp/src/fonts/Graphik/Graphik-Semibold.otf")
    format("opentype");
}

@font-face {
  font-family: Chewy;
  src: url("/wp-content/themes/dlp/src/fonts/Chewy/Chewy-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("/wp-content/themes/dlp/src/fonts/Quicksand/static/Quicksand-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: JandaManateeSolid;
  src: url("/wp-content/themes/dlp/src/fonts/JandaManateeSolid/JandaManateeSolid.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Titillium Web */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* BoldItalic */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* ExtraLight */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-ExtraLight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* ExtraLightItalic */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Italic */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* LightItalic */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-LightItalic.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* SemiBold */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* SemiBoldItalic */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Black */
@font-face {
  font-family: "TitilliumWeb";
  src: url("/wp-content/themes/dlp/src/fonts/TitilliumWeb/TitilliumWeb-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}
