.nav-area {
  position: sticky;
  width: 100%;
  z-index: 99;
  top: 0;

  .top-banner {
    background-color: #000;
    color: #fff;

    p {
      font-size: 18px;
      line-height: 27px;
      font-family: "TitilliumWeb", sans-serif;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 27px;
      }
      &:last-of-type {
        margin-bottom: 0px;
      }

      a {
        text-decoration: none;
        font-weight: 700;
      }
      @media (max-width: 991px) {
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
    .banner-inner-desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 46px;
      gap: 30px;
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 991px) {
        display: none;
      }
    }
    .banner-inner-mobile {
      display: none;

      @media (max-width: 991px) {
        display: block;
      }

      .accordion {
        color: #fff;
        cursor: pointer;
        padding: 15px 20px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-family: "TitilliumWeb", sans-serif;
        font-weight: 700;
        background-color: #000;
        transition: 0.4s;
        font-size: 16px;
        position: relative;
      }

      .accordion:after {
        content: "";
        position: absolute;
        right: 20px;
        background-image: url(/wp-content/themes/dlp/src/images/arrow-white.svg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 9px;
        height: 16px;
        transform: rotate(90deg);
        transition: all 0.2s ease-in-out;
        top: 16px;
      }

      .active:after {
        transform: rotate(270deg);
      }

      .panel {
        padding: 0 18px;
        background-color: #000;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
    }
    .link {
      height: 59px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #ae1834;
      text-decoration: none;
      font-size: 20px;
      font-family: "TitilliumWeb", sans-serif;
      gap: 30px;
      background-color: #ffffff;
      font-weight: 700;
      white-space: nowrap;
      padding: 0px 30px;
      transition: all 0.1s ease-in-out;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        height: unset;
        padding: 10px 20px;
        white-space: unset;
      }
      &:hover {
        scale: 1.03;
      }
    }
  }
}

#nav {
  height: 94px;
  @media (max-width: 576px) {
    height: 60px;
  }
  background: rgb(174, 24, 52);
  background: linear-gradient(
    90deg,
    rgba(174, 24, 52, 1) 0%,
    rgba(117, 41, 56, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    height: 71px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    background: #fff;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .container,
  .row,
  .col-12 {
    height: 100%;
  }
  .nav-inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    .nav-left {
      display: grid;
      grid-template-columns: auto 1fr;
      .links-area {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991px) {
          display: none !important;
        }

        .dropbtn {
          color: white;
          height: 100%;
          font-size: 16px;
          border: none;
          display: block;
          padding: 0px 35px;
          display: flex;
          align-items: center;
          text-decoration: none;
          @media (max-width: 1200px) {
            padding: 0px 28px;
          }
          span {
            padding-bottom: 5px;
          }
        }

        .dropdown {
          .dropbtn {
            span {
              border-bottom: 1px solid transparent;
            }
          }
          &:hover {
            .dropbtn {
              span {
                border-bottom: 1px solid #fff;
              }
            }
          }
        }

        .dropdown.active {
          .dropbtn {
            span {
              border-bottom: 1px solid #fff;
            }
          }
        }

        .dropdown {
          position: relative;
          display: inline-block;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .dropdown-content.dropdown-large {
          grid-template-columns: 1fr 1fr;
          column-gap: 15px;

          grid-auto-flow: column dense;
          a {
            min-width: 220px;
          }
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #fff;
          min-width: 280px;
          padding: 30px 28px 40px 28px;
          top: calc(100% - 10px);
          box-shadow: 0px 25px 25px #00000029;
          z-index: 1;
          border-radius: 15px;
          left: 50%;
          transform: translateX(-50%);

          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
            top: -9px;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .dropdown-content a {
          color: black;
          padding: 0px 11px;
          height: 49px;
          display: flex;
          align-items: center;
          text-decoration: none;
          border-bottom: 1px solid #e4e4e4;
          font-size: 15px;
          &:hover {
            background: #e4e4e4;
          }
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }
        .dropdown:hover .dropdown-content.dropdown-large {
          display: grid;
        }

        .dropdown:hover .dropbtn {
          background-color: #3d050d;
        }
      }
    }
    .nav-right {
      display: flex;
      margin-left: 55px;
      gap: 68px;
      align-items: center;
      @media (max-width: 991px) {
        margin-left: 0px;
        justify-content: flex-end;
        gap: 0px;
      }
      .home-button {
        @media (max-width: 991px) {
          margin-right: 60px;
        }
        @media (max-width: 576px) {
          margin-right: 50px;
        }
        a {
          color: #fff;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #272727;
            span,
            svg {
              color: #272727;
            }
          }
          span {
            margin-top: 5px;
            white-space: nowrap;
          }
          svg,
          img {
            width: 24px;
            margin-right: 11px;
          }
        }
      }

      .type {
        position: relative;
        width: 100%;
        @media (max-width: 991px) {
          display: none;
        }
        .user-type {
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          background: #3d050d 0% 0% no-repeat padding-box;
          height: 57px;
          transform: translateY(-50%);
          padding: 0px 20px;
          border-radius: 10px;
          white-space: nowrap;
          svg,
          img {
            width: 14px;
            margin-right: 17px;
          }

          .desktop {
            display: block;
            @media (max-width: 1400px) {
              display: none;
            }
          }
          .mobile {
            display: none;
            @media (max-width: 1400px) {
              display: block;
            }
          }

          span {
            font-family: "TitilliumWeb", sans-serif;
            font-size: 21px;
            line-height: 28px;
          }
        }
      }
    }
  }
  #logo {
    color: #fff;
    text-decoration: none;
    display: flex;
    svg,
    img {
      width: 104px;
      @media (max-width: 576px) {
        width: 78px;
      }
    }
  }

  #overlay-button {
    position: absolute;
    right: -11px;
    top: 20px;
    padding: 26px 11px;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    @media (max-width: 576px) {
      top: 2px;
    }
    span {
      height: 3px;
      width: 25px;
      background-color: #fff;
      position: relative;
      display: block;
      transition: all 0.2s ease-in-out;
      border-radius: 3px;
      @media (max-width: 767px) {
        width: 26px;
      }
      &:before {
        top: -9px;
        visibility: visible;
      }
      &:after {
        top: 9px;
      }
      &:before,
      &:after {
        height: 3px;
        border-radius: 3px;
        width: 25px;
        background-color: #fff;
        position: absolute;
        content: "";
        transition: all 0.2s ease-in-out;
        @media (max-width: 767px) {
          width: 26px;
        }
      }
    }
  }
  .mobile-menu {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }

  #overlay {
    .menu-item-has-children {
      .slideopen {
        &:after {
          transform: rotate(180deg) scaleX(-1);
        }
      }

      & > a {
        position: relative;

        &:after {
          content: "";
          background-image: url("/wp-content/themes/coquet-cottages/src/images/caret-down.svg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 30px;
          width: 30px;
          position: absolute;
          right: 5%;
          top: -2px;
          transition: all 0.2s ease-in-out;
        }
      }

      & > a {
        display: block;
      }
    }

    ul.sub-menu {
      padding-left: 0px;
      margin-top: 20px;
      list-style: none;
      li {
        padding-bottom: 10px;
        a {
          font-size: 14px;
        }
      }
    }
    overflow: scroll;
    width: 100vw;
    height: 100dvh;
    background: #fff;
    z-index: 2;
    transform: translateX(100vw);
    opacity: 0;

    position: fixed;
    transition: all 0.2s ease-in-out;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: stretch;
    &.active {
      transform: none;
    }
    ul.sub-menu {
      display: none;
    }
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      text-align: center;
      // height: 100vh;
      padding-left: 0;
      list-style-type: none;
      ul {
        padding-left: 0;
        margin-bottom: 0px;
      }
      li {
        padding: 20px 0px;
        &:last-of-type {
          border-bottom: none;
        }
        a {
          display: block;
          color: #000;
          font-size: 24px;
          letter-spacing: 0;
          text-decoration: none;
          line-height: 26px;
          height: 100%;
          width: 100%;
          transition: all 0.1s ease-in-out;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .menu {
      margin-left: 0;
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ #overlay {
    transform: none;
    opacity: 1;
  }

  input[type="checkbox"]:checked ~ #overlay-button {
    position: fixed;
    right: 15px;
    span {
      background-color: #ab1338;
      &:before {
        background-color: #ab1338;
      }
      &:after {
        background-color: #ab1338;
      }
    }
  }
  input[type="checkbox"]:checked ~ #overlay-button {
    &:hover span,
    span {
      background: transparent;
    }
    span {
      &:before {
        transform: rotate(45deg) translate(6px, 6px);
        opacity: 1;
      }
      &:after {
        transform: rotate(-45deg) translate(7px, -7px);
      }
    }
  }

  #overlay {
    visibility: hidden;

    #mobileMenuContact {
      margin-top: 20px;
      width: 130px;
      padding: 10px 0px 11px 0px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      border-radius: 21px;
      background-color: #c90031;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      transition: all 0.1s ease-in-out;
      &:hover {
        background-color: #8a0527;
      }
    }
  }

  .mobile-menu-open {
    visibility: visible !important;
  }

  .mobile-nav-inner {
    color: #272727;
    padding: 102px 30px 0px 30px;
    width: 100%;
    .type-mobile {
      width: 100%;
      color: #fff;
      .user-type {
        left: 0;
        display: flex;
        align-items: center;
        background: #742839;
        width: 100%;
        padding: 15px 20px;
        border-radius: 10px;
        svg,
        img {
          width: 14px;
          margin-right: 17px;
        }

        .desktop {
          display: block;
          @media (max-width: 1400px) {
            display: none;
          }
        }
        .mobile {
          display: none;
          @media (max-width: 1400px) {
            display: block;
          }
        }

        span {
          font-family: "TitilliumWeb", sans-serif;
          font-size: 21px;
          line-height: 28px;
        }
      }
    }

    .link-area-mobile {
      font-family: "TitilliumWeb", sans-serif;
      .item {
        margin-top: 40px;
        a {
          display: block;
          text-align: left;
          margin-bottom: 19px;

          font-weight: 700;
          text-decoration: none;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 7px;
          padding-left: 5px;
          border-bottom: 1px solid #e4e4e4;
        }
      }
    }
  }
}
