//Add components here

@import "_text-image-block";
@import "_text-bubbles";
@import "_text-above-image";
@import "_gradient";
@import "_swoosh";
@import "_icons-and-text";
@import "_downloads";
@import "_text-with-panels";
@import "_form";
@import "_cta-block";
@import "_tabs-block";
@import "_star-rating";
@import "_hcp-logo";
@import "_text_and_images";
@import "interactive-quiz";
@import "content_block";
@import "webinars";
@import "case-studies";
@import "instructions-block";
@import "pie-chart-block";
@import "graph-block";
@import "video-block";
