.content-block {
  padding: 50px 0px;
  h1,
  h2 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 25px;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  p {
    margin-bottom: 35px;
    text-align: center;
  }
  .under-panel-content,
  .content {
    h2 {
      font-family: var(--brandfont);
    }
    p {
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
  .under-panel-content {
    margin-top: 35px;
  }
  .panel {
    padding: 30px;
    border-radius: 40px;
    margin-top: 25px;
    &:first-of-type {
      margin-top: 0px;
    }
    @media (max-width: 767px) {
      box-shadow: 0px 3px 6px #00000029;
    }
    h3 {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}
