.downloads-page-form {
  padding: 100px 0px;
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  .profile-icon {
    width: 82px;
    height: 82px;
    margin: auto;
    border-radius: 50%;
    border: 4px solid white;
    padding: 0.5rem;
    display: block;
    margin-bottom: 30px;
  }
  h2 {
    font-family: "Graphik Semibold", sans-serif;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
  }
  .gfield_label {
    display: none !important;
  }

  .gform_fields {
    row-gap: 15px !important;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr !important;
      grid-auto-flow: column !important;

      .right-column {
        grid-column-start: 2 !important;
        grid-row: 1 / 7 !important;
        padding: 1rem;
        border: 1px solid white;
        border-radius: 20px;
      }

      .gfield {
        grid-column: 1 / 2 !important;
      }
    }
    .right-column {
      padding: 1rem;
      border: 1px solid white;
      border-radius: 20px;
    }
    @media (max-width: 767px) {
      display: block !important;

      .gfield {
        margin-bottom: 15px;
      }
    }
  }

  h3 {
    font-family: "Graphik Semibold", sans-serif;
    color: #fff;
    text-align: center;
    font-size: 30px;
  }

  p {
    color: #fff;
    line-height: 1.25;
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0px;
  }

  label {
    color: #fff !important;
    line-height: 1.25 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  input[type="text"],
  input[type="email"] {
    border-radius: 15px !important;
    padding: 0.5rem 1rem;
    background: white;
    font-family: "TitilliumWeb" !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-align: left;
    min-height: 50px !important;
    border: none !important;
    margin-bottom: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    width: 100%;
    border: none !important;
  }

  input[type="submit"] {
    width: 230px !important;
    height: 59px !important;
    border-radius: 18px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    color: #fff !important;
    text-decoration: none !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    transition: all 0.1s ease-in-out !important;
    margin: 0 auto !important;
    &:hover {
      transform: scale(1.05);
    }
  }

  .gform_validation_errors {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    padding: 0px !important;
    text-align: left;
    h2 {
      text-transform: none !important;
      font-size: 16px !important;
      line-height: 23px !important;
    }
  }

  .gform_wrapper.gravity-theme .gfield_error [aria-invalid="true"] {
    border: 1px solid #c02b0a !important;
  }
  .gfield_validation_message {
    padding: 0px !important;
    border: none !important;
    background: none !important;
  }
}

.downloads-page {
  padding: 100px 0px;
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  h2 {
    margin-bottom: 0px;
    font-family: "TitilliumWeb";
    font-weight: 600;
  }

  .download-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    gap: 19px;

    @media (max-width: 576px) {
      display: block;
    }

    .download-item {
      max-width: 278px;
      text-decoration: none;
      flex: 1;
      @media (max-width: 576px) {
        max-width: unset;
      }
      @media (max-width: 576px) {
        margin-top: 35px;
      }
    }
    h3 {
      margin-top: 15px;
      margin-bottom: 0px;
      font-family: "TitilliumWeb";
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 21px;
        line-height: 30px;
      }
    }

    .download-btn {
      margin: 20px auto 0px auto;
      width: 209px;
      height: 59px;
      border-radius: 18px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      transition: all 0.1s ease-in-out;
      font-family: "TitilliumWeb";

      &:hover {
        scale: 1.07;
      }
    }

    .image-container {
      position: relative;
      margin: 0 auto;
      width: 278px;
      aspect-ratio: 1;
      @media (max-width: 576px) {
        width: auto;
      }
      img {
        object-fit: contain;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
