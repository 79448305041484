.interactive-quiz {
  padding: 50px 0px;

  h2.h1 {
    text-align: center;
    margin-bottom: 0px;
  }
  p {
    text-align: center;
    max-width: 750px;
    margin: 15px auto 0px auto;
  }

  #quiz-container {
    width: 100%;
    max-width: 455px;
    margin: auto;
    text-align: center;
  }

  #restart {
    display: none;
  }

  #quiznav {
    display: none;
  }

  #questionNumber {
    font-weight: 700;
  }
  .quiz-card {
    width: 100%;
    background-color: #fff;
    color: #272727;
    margin: 25px 0;
    border-radius: 40px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 20px 30px;
    @media (max-width: 576px) {
      min-height: 250px;
    }
  }

  .question {
    max-width: 340px;
    margin: 20px auto 0px auto;

    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0px;
    @media (max-width: 576px) {
      font-size: 21px;
      line-height: 30px;
    }
  }

  #answer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #reasoning {
    margin-top: 25px;
    p {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0px;
      @media (max-width: 576px) {
        font-size: 21px;
        line-height: 30px;
      }
    }
  }

  #answer {
    font-size: 25px;
    font-weight: 700;

    .true {
      color: #71a200;
    }

    .false {
      color: #ae1834;
    }
  }

  #quiznav {
    align-items: center;
    justify-content: center;
  }

  #result {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }

  #true {
    appearance: none;
    border: 2px solid #71a200;
    background: transparent;
    width: 137px;
    height: 59px;
    border-radius: 18px;
    color: #71a200;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    margin: 0px 10px;
    &:hover {
      background: #71a200;
      color: #fff;
    }
  }

  #false {
    appearance: none;
    border: 2px solid #ae1834;
    background: transparent;
    width: 137px;
    height: 59px;
    border-radius: 18px;
    color: #ae1834;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    margin: 0px 10px;

    &:hover {
      background: #ae1834;
      color: #fff;
    }
  }

  #prev {
    appearance: none;
    border: 2px solid #272727;
    background: transparent;
    width: 137px;
    height: 59px;
    border-radius: 18px;
    color: #272727;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    margin: 0px 10px;
    &:hover {
      background: #272727;
      color: #fff;
    }
  }
  #restart {
    appearance: none;
    border: 2px solid #272727;
    background: transparent;
    width: 137px;
    height: 59px;
    border-radius: 18px;
    color: #272727;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    margin: 0px 10px;

    &:hover {
      background: #272727;
      color: #fff;
    }
  }

  #next {
    appearance: none;
    border: 2px solid #272727;
    background: transparent;
    width: 137px;
    height: 59px;
    border-radius: 18px;
    color: #272727;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    margin: 0px 10px;

    &:hover {
      background: #272727;
      color: #fff;
    }
  }
}
