.text-and-images {
  padding: 50px 0px;

  .intro-content {
    h1,
    h2 {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 20px;
      text-align: center;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;

      br {
        display: block;
        margin: 20px 0;
        content: " ";
      }
      @media (max-width: 576px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    p {
      margin-bottom: 35px;
      text-align: center;
      max-width: 575px;
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      font-family: var(--brandfont);
    }
  }

  .image-item {
    &:last-of-type {
      margin-bottom: 0px;
    }
    margin-bottom: 25px;
    h3,
    p {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 0px;
    }

    h3 {
      @media (max-width: 576px) {
        margin-top: 10px;
      }
    }
    p {
      @media (max-width: 576px) {
        margin-top: 10px;
      }
    }
  }

  .i2 {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    gap: 20px;
    @media (max-width: 1200px) {
      gap: 20px;
    }
    @media (max-width: 767px) {
      gap: 20px;
    }
    @media (max-width: 576px) {
      display: block;
      margin-top: 0px !important;
    }
    .image-item {
      max-width: 475px;
      width: 100%;
      @media (max-width: 1200px) {
        max-width: unset;
      }
    }
  }

  .i3 {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .image-item {
      max-width: 377px;
      width: 100%;
      @media (max-width: 767px) {
        max-width: unset;
      }
    }

    .image-container {
      max-width: 200px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      display: block;
      margin-top: 0px !important;
    }
  }

  .description {
    p {
      max-width: 575px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      text-align: center;
      @media (max-width: 576px) {
        margin-top: 15px;
      }
    }
  }
  .panel {
    padding: 30px;
    border-radius: 40px;
    max-width: 575px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    @media (max-width: 767px) {
      box-shadow: 0px 3px 6px #00000029;
    }
    @media (max-width: 576px) {
      margin-top: 30px;
    }
    h3 {
      margin-bottom: 0px;
      text-align: center;
    }
  }

  .image-container {
    padding-top: 63%;
    position: relative;

    @media (max-width: 767px) {
      margin-left: 0px;
      margin-right: 0px;
    }
    img {
      object-fit: contain;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
