.text-with-panels {
  padding: 50px 0px;
  h1,
  h2 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 25px;
    @media (max-width: 576px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  .text-with-panels-inner {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      display: block;
    }
    > div {
      flex: 1;
    }

    p {
      margin-bottom: 35px;
    }

    .panel {
      padding: 30px;
      border-radius: 40px;
      margin-top: 25px;
      &:first-of-type {
        margin-top: 0px;
      }
      @media (max-width: 767px) {
        box-shadow: 0px 3px 6px #00000029;
      }
      h3 {
        margin-bottom: 0px;
      }
    }

    .panels {
      .panels-inner {
        margin-left: 115px;
        @media (max-width: 991px) {
          margin-left: 80px;
        }
        @media (max-width: 767px) {
          margin-left: 0px;
        }
      }
    }
  }
}
