.instructions-block {
  padding: 50px 0px;
  h2 {
    margin-bottom: 0px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    margin-top: 50px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 576px) {
      display: block;
    }
    .icon-item {
      @media (max-width: 576px) {
        margin-top: 25px;
      }
    }
    h2 {
      margin-top: 25px;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      margin-top: 7px;
    }

    .image-container {
      padding-top: 55%;
      position: relative;
      max-width: 300px;
      margin: 0 auto;
      img {
        object-fit: contain;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
