.text-bubbles {
  padding: 50px 0px;

  h3 {
    margin-top: 25px;
  }

  p {
    margin-top: 25px;
    &:first-child {
      margin-top: 0px;
    }
  }

  .b1 {
    @media (max-width: 576px) {
      margin-top: 20px !important;
    }
    .bubble {
      max-width: 455px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .b2 {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1200px) {
      width: 100%;
      gap: 49px;
    }
    @media (max-width: 767px) {
      gap: 20px;
    }
    @media (max-width: 576px) {
      display: block;
      margin-top: 0px !important;
    }
    .bubble {
      max-width: 455px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 576px) {
        margin-top: 20px;
      }
    }
  }

  .b3 {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1200px) {
      margin-top: 0px !important;
      display: block;
    }

    .bubble {
      max-width: 377px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 1200px) {
        max-width: 80%;
        margin-top: 20px;
      }
      @media (max-width: 991px) {
        max-width: 90%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .b4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 60px;
    width: fit-content;
    grid-row-gap: 60px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      display: block;
      margin-top: 0px !important;
    }
    .bubble {
      max-width: 455px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }

  .b5 {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .bubble {
      max-width: 575px;
      width: 100%;
    }
  }

  .bubbles-container {
    margin-top: 30px;
    .bubble {
      border-radius: 40px;
      padding: 30px 20px;
      box-shadow: 0px 3px 6px #00000029;
      height: fit-content;
      width: 100%;
      h2 {
        p {
          font-size: 25px;
          line-height: 30px;
          letter-spacing: 0px;
          @media (max-width: 576px) {
            font-size: 21px;
            line-height: 30px;
          }
        }
      }
      p {
        margin-top: 22px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
}
