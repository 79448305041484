.text-above-image {
  padding: 50px 0px 20px 0px;

  .text-image-block-inner {
    h1,
    h2 {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 20px;
      text-align: center;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;

      br {
        display: block;
        margin: 20px 0;
        content: " ";
      }
      @media (max-width: 576px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    p {
      margin-bottom: 35px;
      text-align: center;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .panel {
      padding: 30px;
      border-radius: 40px;
      max-width: 575px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      @media (max-width: 767px) {
        box-shadow: 0px 3px 6px #00000029;
      }
      h3 {
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }

  .image.horizontal {
    .image-container {
      padding-top: 50%;
    }
  }
  .image {
    margin-left: auto;
    margin-right: auto;
    // max-width: 475px;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }

    @media (max-width: 576px) {
      img {
        &:first-of-type {
          display: block;
        }
        &:nth-of-type(2) {
          display: none !important;
        }
      }
    }
    .mobile-image {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
