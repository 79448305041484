.password-area {
  padding: 80px 0px;
  @media (max-width: 767px) {
    padding: 40px 0px;
  }
  .back-btn {
    font-size: 21px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
  }

  .form-area {
    margin-top: 70px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
    h2 {
      color: #ae1834;
      font-weight: 600;
      font-size: 34px;
      line-height: 1.33;
      text-align: center;
      font-family: Graphik Semibold, sans-serif;
      @media (min-width: 900px) {
        font-size: 45px;
      }
    }

    form {
      margin: auto;
      max-width: 560px;
      width: 100%;
      text-align: center;
      display: grid;
      gap: 40px;
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 20px;
        gap: 20px;
      }
    }

    .fail-text {
      color: red;
    }
    input[type="password"] {
      padding: 0.5rem 2rem;
      background: white;
      font-weight: normal;
      font-size: 21px;
      line-height: 1;
      text-align: left;
      min-height: 50px;
      border: 2px solid rgb(228, 228, 228);
      width: 100%;
      background: rgb(228, 228, 228);
      height: 59px;
      border-radius: 20px;
    }

    input[type="submit"] {
      height: 59px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      background-color: #ae1834;
      font-family: "TitilliumWeb", sans-serif;
      gap: 30px;
      font-weight: 700;
      white-space: nowrap;
      outline: none;
      border: none;
      padding: 0px 45px;
      transition: all 0.1s ease-in-out;
      position: relative;
    }

    p {
      margin-top: 35px;
      text-align: center;
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
}
