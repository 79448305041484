#footer {
  padding: 63px 0px;
  background-color: #434343;
  color: #fff;
  @media (max-width: 576px) {
    padding: 35px 0px;
  }
  .footer-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 130px;
    position: relative;
    @media (max-width: 767px) {
      display: block;
    }
    &:before {
      content: "";
      width: 1px;
      background-color: #fff;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        display: none;
      }
    }
    .footer-left {
      @media (max-width: 767px) {
        margin-bottom: 35px;
      }
      .information {
        width: 90%;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          margin-bottom: 35px;
        }
        p {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
        a {
          color: #fff;
          display: block;
          width: auto;
          font-family: Graphik Semibold;
          font-size: 18px;
          line-height: 24px;
          text-decoration: none;
          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 21px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .addresses {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 60px;
        @media (max-width: 576px) {
          display: block;
        }

        .uk {
          @media (max-width: 576px) {
            margin-bottom: 35px;
          }
        }
        svg,
        img {
          max-width: 149px;
          display: block;
          margin-bottom: 26px;
          @media (max-width: 576px) {
            max-width: 120px;
          }
        }

        p {
          @media (max-width: 576px) {
            font-size: 14px;
            line-height: 18px;
          }
        }

        a {
          color: #fff;
          display: block;
          width: auto;
          font-family: Graphik Semibold;
          text-decoration: none;
          @media (max-width: 576px) {
            font-size: 14px;
            line-height: 18px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footer-right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      p {
        margin-bottom: 30px;
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .fr-info {
        .jn-desktop {
          display: block;
          @media (max-width: 576px) {
            display: none;
          }
        }
        .jn-mobile {
          display: none;
          @media (max-width: 576px) {
            display: block;
          }
        }
        a {
          color: #fff;
          display: block;
          width: auto;
          font-family: Graphik Regular;
          text-decoration: none;
          margin-bottom: 25px;
          @media (max-width: 576px) {
            font-size: 14px;
            line-height: 18px;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        span {
          display: block;
          margin-bottom: 25px;
          @media (max-width: 576px) {
            font-size: 14px;
            line-height: 18px;
          }
          &:last-of-type {
            margin-bottom: 0px;
          }

          strong {
            font-family: Graphik Semibold;
          }
        }
      }
    }
  }
}
