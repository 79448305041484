.cta-block {
  padding: 50px 0px;

  .cta-item {
    padding-bottom: 50px;
    border-bottom: 1px solid #272727;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    align-items: flex-end;
    gap: 25px;
    @media (max-width: 767px) {
      display: block;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0px;
    }
    &:first-of-type {
      padding-top: 0px;
    }
    .content {
      max-width: 475px;

      p {
        margin-top: 20px;
        margin-bottom: 0px;
      }
    }
    .link {
      width: 230px;
      height: 59px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      transition: all 0.1s ease-in-out;
      @media (max-width: 767px) {
        margin-top: 25px;
      }
      &:hover {
        scale: 1.07;
      }
    }
  }
}
